
import { defineComponent, ref } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import PageBlock from '@/components/layout/PageBlock.vue'
import DetailsHero from '@/components/shared/templates/details/DetailsHero.vue'
import InfoCard from '@/components/shared/templates/InfoCard.vue'
import InfoCardRow from '@/components/shared/templates/InfoCardRow.vue'
import type { BreadcrumbsLink } from '@/definitions/shared/types'
import { capitalize } from '@/services/utils'

export default defineComponent({
  components: {
    TmModal,
    TmButton,
    TmFormLine,
    PageBlock,
    DetailsHero,
    InfoCard,
    InfoCardRow,
  },
  setup() {
    const breadcrumbsLink = ref<BreadcrumbsLink[]>([
      {
        label: 'Contacts',
        name: 'base.contacts',
      },
      {
        label: 'Settings',
      },
    ])
    const groupName = ref('organization')
    const groupNameValue = ref(capitalize(groupName.value))
    const groupLabelModal = ref()
    const openGroupLabelModal = () => {
      groupLabelModal.value.openModal()
    }
    const handleClick = (hideModal: () => void) => {
      groupName.value = groupNameValue.value
      hideModal()
    }

    return {
      breadcrumbsLink,
      groupName,
      groupNameValue,
      capitalize,
      groupLabelModal,
      openGroupLabelModal,
      handleClick,
    }
  },
})
